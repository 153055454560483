import { List,Avatar  } from 'antd';
import React from 'react';
import {getUserToken,getUserID} from '../store/utility';
import axios from "axios";
import moment from "moment";
import {SendTracking} from "../utils/api_callbacks";

class DownloadStandalones extends React.Component{

    state = {
        token : "",
        connected_user_id : -1,
        user_staff : false,
        builds:'',
        excluded_os : ["ANDROID","IOS"],

    }

    getPNGForOS = (item) => {
        if (item.os.toLowerCase().includes("osx")){
            if (item.architecture !== undefined && item.architecture.toLowerCase().includes("silicon")){
                return "images/refonte/m1.png";
            }
            else {
                return "images/refonte/logo_mac2.png";
            }

        }
        if (item.os.toLowerCase().includes("window")){
            if (item.architecture !== undefined && item.architecture.toLowerCase().includes("gpu")){
                return "images/refonte/logo_windows_gpu2.png";
            }
            else {
                return "images/refonte/logo_windows.png";
            }
        }
        if (item.os.toLowerCase().includes("ios")){
            return "images/refonte/logo_ios.png";
        }
        if (item.os.toLowerCase().includes("android")){
            return "images/refonte/log_android.png";
        }
        if (item.os.toLowerCase().includes("linux")){
            if (item.architecture !== undefined && item.architecture.toLowerCase().includes("gpu")){
                return "images/refonte/logo_linux_gpu2.png";
            }
            else {
                return "images/refonte/logo_linux2.png";
            }
        }
    }

    componentDidMount(){
        this.state.connected_user_id =  getUserID();
        this.state.token = getUserToken();
        if (getUserToken()!=="" && getUserToken() !== undefined && getUserToken() !== null){
            axios.get('/api/standalonelist/', { headers: { Authorization: `Token ${this.state.token}` } })
                .then(res => {
                    this.setState({builds:res.data['builds']});
                });
        }
        else{
            axios.get('/api/standalonelistpublic/')
                .then(res => {
                    this.setState({builds:res.data['builds']});
                });
        }

    }
    versionCompare = (v1, v2, options)=>  {
        var lexicographical = options && options.lexicographical,
            zeroExtend = options && options.zeroExtend,
            v1parts = v1.split('.'),
            v2parts = v2.split('.');

        function isValidPart(x) {
            return (lexicographical ? /^\d+[A-Za-z]*$/ : /^\d+$/).test(x);
        }

        if (!v1parts.every(isValidPart) || !v2parts.every(isValidPart)) {
            return NaN;
        }

        if (zeroExtend) {
            while (v1parts.length < v2parts.length) v1parts.push("0");
            while (v2parts.length < v1parts.length) v2parts.push("0");
        }

        if (!lexicographical) {
            v1parts = v1parts.map(Number);
            v2parts = v2parts.map(Number);
        }

        for (var i = 0; i < v1parts.length; ++i) {
            if (v2parts.length == i) {
                return 1;
            }

            if (v1parts[i] == v2parts[i]) {
                continue;
            }
            else if (v1parts[i] > v2parts[i]) {
                return 1;
            }
            else {
                return -1;
            }
        }

        if (v1parts.length != v2parts.length) {
            return -1;
        }

        return 0;
    }

    download = (item) => {
        SendTracking(-1, "Web,Standalone,Download,"+item.os+(item.architecture !== undefined?"-"+item.architecture:"")+","+item.build+","+item.version.replace("v","")+"-"+item.git);

        const url = item.path.replace("/var/www","")
        const a = document.createElement('a')
        a.href = url
        a.download = url.split('/').pop()
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
        /*fetch(url)
            .then(response => response.blob())
            .then(blob => {
                const link = document.createElement("a");
                link.href = URL.createObjectURL(blob);
                link.download = "MorphoNet.zip";
                link.click();
            })
            .catch(console.error);*/
}


    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevState.builds !== this.state.builds){
            let stablebuilds_temp = [];
            let builds_real_temp = [];
            //Builds received from Django
            this.state.builds.forEach(element => {
                let is_silicon = false;
                let pushed = false;
                if (!this.state.excluded_os.includes(element.os)){
                if (element.build == "stable"){
                        let add= true;
                        if (stablebuilds_temp.length < 1){
                            stablebuilds_temp.push(element);
                        }else{
                            stablebuilds_temp.forEach(build => {
                                let builddate = moment(build.date , "DD/MM/YYYY hh:mm:ss").toDate(); ;
                                let elementdate = moment(element.date, "DD/MM/YYYY hh:mm:ss").toDate(); ;
                                if (this.versionCompare(build.version,element.version) >= 0 && builddate >= elementdate) {
                                    if ((build.os === "OSX" && element.os === "OSX")) {
                                        if (element.architecture === build.architecture)
                                            add = false;
                                    }
                                    else if ((build.os === "LINUX" && element.os === "LINUX") || (build.os === "WINDOWS" && element.os === "WINDOWS")){
                                        // #TODO
                                        if ((element.architecture == undefined && build.architecture == undefined) || (element.architecture == "GPU" && build.architecture == "GPU")){
                                            add = false;
                                        }
                                    }
                                    else if (element.os === build.os){
                                        add = false;
                                    }
                                }
                            });
                            if (add){
                                stablebuilds_temp.forEach(build => {
                                    if (element.os === build.os) {
                                        if (element.os === "OSX"){
                                            if (element.architecture === build.architecture){
                                                const index = stablebuilds_temp.indexOf(build);
                                                if (index > -1) { // only splice array when item is found
                                                    stablebuilds_temp.splice(index, 1); // 2nd parameter means remove one item only
                                                }
                                            }
                                        }
                                        else if (element.os === "LINUX"){
                                            // #TODO
                                            if ((element.architecture == undefined && build.architecture == undefined) || (element.architecture == "GPU" && build.architecture == "GPU")){
                                                const index = stablebuilds_temp.indexOf(build);
                                                if (index > -1) { // only splice array when item is found
                                                    stablebuilds_temp.splice(index, 1); // 2nd parameter means remove one item only
                                                }
                                            }
                                        }
                                        else if (element.os === "WINDOWS"){
                                            // #TODO
                                            if ((element.architecture == undefined && build.architecture == undefined) || (element.architecture == "GPU" && build.architecture == "GPU")){
                                                const index = stablebuilds_temp.indexOf(build);
                                                if (index > -1) { // only splice array when item is found
                                                    stablebuilds_temp.splice(index, 1); // 2nd parameter means remove one item only
                                                }
                                            }
                                        }
                                        else {
                                            const index =stablebuilds_temp.indexOf(build);
                                            if (index > -1) { // only splice array when item is found
                                                stablebuilds_temp.splice(index, 1); // 2nd parameter means remove one item only
                                            }
                                        }

                                    }
                                });
                                stablebuilds_temp.push(element);
                            }
                        }

                    }
                else {
                    builds_real_temp.push(element);
                }

                }

            });
            stablebuilds_temp.sort(function(x, y) {
                    if (x.os < y.os) {
                        return -1;
                    }
                    if (x.os > y.os) {
                        return 1;
                    }
                    return 0;
                });
            
            /*let build_real = [...,  builds_real_temp]
            let stablebuilds = [...,stablebuilds_temp]
            // Set state builds_real builds_real
            //Set state stablebuilds stablebuilds
            this.setState(oldState => {
                return {
                    ...oldState,
                    build_real,
                    stablebuilds
                }

            });*/
            this.setState({builds_real:builds_real_temp});
            this.setState({stablebuilds:stablebuilds_temp});
            //this.setState({...,builds_real:builds_real_temp});
            //this.setState({...,builds_real:builds_real_temp});
            //this.setState({...,stablebuilds:stablebuilds_temp});
            //this.setState({stablebuilds:[...stablebuilds]})
        }
    }



    render(){
        return(
            <div style={{marginTop: '6%'}}>

                <h1 style={{
                    color: '#292e37',
                    fontFamily: 'Baskerville',
                    fontSize: '34px',
                    textAlign: 'center',
                    fontWeight: "bold",
                    minWidth: '750px'
                }}> Download MorphoNet Standalone Application </h1>
                <h style={{fontSize: '16px',marginTop:"1%"}}>If you need help to install MorphoNet Standalone Application , please <a style={{fontSize: '16px'}} href="https://morphonet.org/help_standalone">click here</a></h>
                <br></br>
                <br></br>
                <br></br>
                <h style={{fontSize: '16px',marginTop:"1%",fontStyle:"italic"}}>Note : The GPU versions of the application allow you to run Cellpose-train and Cellpose-predict using the GPU of your machine if it is a NVIDIA card, and the drivers. Please note that the Silicon version supports Cellpose-predict using the silicon chip as "GPU", but not Cellpose-train (that will use the CPU).</h>
                <div>
                    {
                        this.state.stablebuilds !== undefined && this.state.stablebuilds.length > 0 &&
                        <div>
                            <br/><br/>
                            <p style={{
                                color: '#44D4FF',
                                fontFamily: 'Arial',
                                fontSize: '28px',
                                fontWeight: "bold"
                            }}> Stable</p>
                            <List
                                itemLayout="horizontal"
                                dataSource={this.state.stablebuilds}
                                renderItem={(item) => (
                                    <List.Item>
                                        <List.Item.Meta
                                            avatar={<Avatar shape="square" style={{
                                                width: '48px',
                                                height: '48px'
                                            }} src={this.getPNGForOS(item)}></Avatar>}
                                            title={<div style={{marginTop: '15px'}}><a
                                                style={{fontSize: '20px', color: 'black'}}

                                                onClick={() => {
                                                    this.download(item);
                                                }}>Version
                                                ({item.version.replace("v", "")}-{item.git}){item.architecture !== undefined && item.architecture.toLowerCase() === "gpu" ? " Experimental" : ""} </a><a
                                                style={{fontSize: '18px'}}
                                                onClick={() => {
                                                    this.download(item);
                                                }}>{item.architecture !== undefined && (item.os === "OSX" && item.architecture.toLowerCase() === "silicon") ? "OSX Silicon" : item.architecture !== undefined && (item.os === "LINUX" && item.architecture.toLowerCase() === "gpu") ? "Linux GPU" : item.architecture !== undefined && (item.os === "WINDOWS" && item.architecture.toLowerCase() === "gpu") ? "Windows GPU" : item.os} ({item.build})
                                                - {item.date}
                                            </a><a style={{fontStyle: "oblique underlined"}}
                                                   href={"https://gitlab.inria.fr/MorphoNet/morphonet_unity/-/tree/" + item.git}> (link
                                                to GIT page)</a></div>}
                                        />
                                    </List.Item>
                                )}
                            />

                        </div>}
                </div>


                {
                    this.state.builds_real !== undefined && this.state.builds_real.length !== 0 &&

                    <div>
                        <br/><br/><br/><br/>
                        <p style={{color: '#44D4FF', fontFamily: 'Arial', fontSize: '28px', fontWeight: "bold"}}> Other
                            versions</p>
                        <List
                            itemLayout="horizontal"
                            dataSource={this.state.builds_real}
                            renderItem={(item) => (
                                <List.Item>
                                    <List.Item.Meta
                                        avatar={<Avatar shape="square" style={{
                                            width: '32px',
                                            height: '32px'
                                        }} src={this.getPNGForOS(item)}></Avatar>}
                                        title={<div style={{marginTop: '15px'}}><a
                                            style={{fontSize: '20px', color: 'black'}}
                                            onClick={() => {
                                                this.download(item);
                                            }}>Version
                                            ({item.version.replace("v", "")}-{item.git}){item.architecture !== undefined && item.architecture.toLowerCase() === "gpu" ? " Experimental" : ""} </a><a
                                            style={{fontSize: '18px'}}
                                            onClick={() => {
                                                this.download(item);
                                            }}>{item.architecture !== undefined && (item.os === "OSX" && item.architecture.toLowerCase() === "silicon") ? "OSX Silicon" : item.architecture !== undefined && (item.os === "LINUX" && item.architecture.toLowerCase() === "gpu") ? "Linux GPU" : item.architecture !== undefined && (item.os === "WINDOWS" && item.architecture.toLowerCase() === "gpu") ? "Windows GPU" : item.os} ({item.build})
                                            - {item.date}
                                        </a><a style={{fontStyle: "oblique underlined"}}
                                               href={"https://gitlab.inria.fr/MorphoNet/morphonet_unity/-/tree/" + item.git}> (link
                                            to GIT page)</a></div>}
                                    />
                                </List.Item>
                            )}
                        />
                    </div>
                }


            </div>
        );
    }
}

export default DownloadStandalones;